<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">멤버 관리</h3>
			</div>

			<div
				class="mt-10 min-height-480"
			>
				<div class="pt-20">
					<button
						:class="{'btn-primary': item_search.status === 0}"
						@click="item_search.status = 0; getWaitMember()"
						class="btn-inline btn-gray radius-20 mr-10"
					>승인대기</button>
					<button
						:class="{'btn-primary': item_search.status == 1}"
						@click="item_search.status = 1; getData()"
						class="btn-inline btn-gray radius-20 mr-10"
					>멤버</button>
					<button
						:class="{'btn-primary': item_search.status == 2}"
						@click="item_search.status = 2; getStopMember()"
						class="btn-inline btn-gray radius-20 mr-10"
					>정지멤버</button>
				</div>

				<div>
					<div class="mt-20 box radius-20 flex-row justify-space-between items-center">
						<input
							v-model="item_search.search_value"
							type="text" id="cartelDesc" placeholder="회원명을 입력하세요" maxlength="20"
							class="flex-1 pa-10-20"
							@keyup.enter="getData"
						>

						<v-icon
							v-if="item_search.search_value"
							@click="item_search.search_value = ''"
							class="mr-10 size-px-20"
						>mdi-close-circle</v-icon>

						<span class="mr-20 size-px-14">{{ item_search.search_value.length }}/20</span>
					</div>
				</div>

				<div class="mt-30">
					<div
						v-if="item_search.status === 0"
					>
						<div class="flex-row justify-space-between">
							<h5 class="itemlist_head col_primary">승인대기 멤버</h5>
							<span class="m_count">{{ tot_member_count }}명</span>
						</div>
						<div
							style="max-height: 480px; overflow: auto"
							class="mt-10 pr-10"
						>
							<ul
								v-if="items_wait.length > 0"
							>
								<li
									v-for="(item_wait, index) in items_wait"
									:key="'wait_' + index"
									class="mt-10 pb-10 under-line"
								>
									<div class="flex-row justify-space-between items-center">
										<div class="flex-row items-center">
											<div class="square-64 radius-100 overflow-hidden mr-10">
												<img
													:src="$request.upload_url(item_wait.profle_nft_card_img_url)"
													@error="$bus.$emit('onErrorProfileImage', $event)"
													class="width-100"
												/>
											</div>
											<div class="text_area">
												<strong>{{  item_wait.nickname }}</strong>
												<p class="textzone">
													{{ item_wait.cartl_member_grade_name}}
												</p>
											</div>
										</div>
										<div>
											<button
												@click="onConfirm('join_confirm', item_wait)"
												class="btn-inline btn-primary-outline radius-20 pa-5-10 mr-10"
											>승인</button>
											<button
												@click="onConfirm('join_cancel', item_wait)"
												class="btn-inline btn-primary-outline radius-20 pa-5-10"
											>삭제</button>
										</div>
									</div>
								</li>
							</ul>

							<div
								v-else
								class="none mt-30"
							>
								<span>조회가능한 멤버가 없습니다.</span>
							</div>
						</div>
					</div>


					<div
						v-else-if="item_search.status == 1"
					>
						<div class="flex-row justify-space-between">
							<h5>멤버</h5>
							<span>{{ tot_member_count }}명</span>
						</div>
						<div
							style="max-height: 480px; overflow: auto"
							class="mt-10 pr-10"
						>
							<ul
								v-if="items_member.length > 0"
							>
								<li
									v-for="(item_member, index) in items_member"
									:key="'member_' + index"
									class="mt-10 pb-10 under-line"
								>
									<div class="flex-row justify-space-between items-center">
										<div class="flex-row items-center">
											<div class="square-64 radius-100 overflow-hidden mr-10">
												<img
													:src="$request.upload_url(item_member.profle_nft_card_img_url)"
													@error="$bus.$emit('onErrorProfileImage', $event)"
													class="width-100"
												/>
											</div>
											<div class="text_area">
												<strong>{{  item_member.nickname }}</strong>
												<p class="textzone">
													{{ item_member.cartl_member_grade_name}}
												</p>
											</div>
										</div>
										<div
											class=""
										>
											<template
												v-if="item_member.cartl_member_div_code == 'CA02500003'"
											>
												<button

													@click="onConfirm('deactivate', item_member)"
													class="btn-inline btn-primary-outline radius-20 pa-5-10 mr-10"
												>정지</button>
												<button
													@click="onConfirm('out', item_member)"
													class="btn-inline btn-primary-outline radius-20 pa-5-10"
												>강제탈퇴</button>
											</template>
										</div>
									</div>
								</li>
							</ul>

							<div
								v-else
								class="none mt-30"
							>
								<span>조회가능한 멤버가 없습니다.</span>
							</div>
						</div>
					</div>

					<div
						v-else-if="item_search.status === 2"
					>
						<div class="flex-row justify-space-between">
							<h5>정지 멤버</h5>
							<span>{{ tot_member_count }}명</span>
						</div>
						<div
							style="max-height: 480px; overflow: auto"
							class="mt-10 pr-10"
						>
							<ul
								v-if="items_deactivate.length > 0"
							>
								<li
									v-for="(item_deactivate, index) in items_deactivate"
									:key="'wait_' + index"
									class="mt-10 pb-10 under-line"
								>
									<div class="flex-row justify-space-between items-center">
										<div class="flex-row items-center">
											<div class="square-64 radius-100 overflow-hidden mr-10">
												<img
													:src="$request.upload_url(item_deactivate.profle_nft_card_img_url)"
													@error="$bus.$emit('onErrorProfileImage', $event)"
													class="width-100"
												/>
											</div>
											<div class="text_area">
												<strong>{{  item_deactivate.nickname }}</strong>
												<p class="textzone">
													{{ item_deactivate.cartl_member_grade_name }}
													{{ item_deactivate.stop_enddate_count }}일 남았습니다
												</p>
											</div>
										</div>
										<div>
											<button
												@click="onConfirm('safe', item_deactivate)"
												class="btn-inline btn-primary-outline radius-20"
											>해제</button>
										</div>
									</div>
								</li>
								</ul>

							<div
								v-else
								class="none mt-30"
							>
								<span>조회가능한 멤버가 없습니다.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<PopupConfirm
			v-if="is_confirm"

			@click="doConfirm"
			@cancel="cancelConfirm"
			class="text-center"
		>
			<template
				v-slot:title
			>{{ confirm_option.name }}</template>
			<template
				v-slot:main-txt
			>
				{{ confirm_option.main_txt }}
			</template>
			<template
				v-slot:sub-txt
			>
				{{ confirm_option.sub_txt }}
				<ul
					v-if="confirm_type == 'deactivate'"
				>
					<li
						v-for="deact in items_deact"
						:key="'deact_' + deact.code"
						class="pa-10"
					><label><input v-model="deact_days" type="radio" :value="deact.code" /> {{  deact.key }}일간 활동 정지</label></li>
				</ul>
			</template>
		</PopupConfirm>
	</div>
</template>

<script>
import PopupConfirm from "@/view/Layout/PopupConfirm";
import CartelSetting from "@/view/Cartel/CartelSetting";
export default {
	name: 'mafia091'
	, props: ['user']
	, components: {CartelSetting, PopupConfirm}
	, data: function(){
		return {
			program: {
				name: '멤버 관리'
				, title: '멤버 관리'
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: true
				, bg_contents: 'bg-gray01'
			}
			, items_member: []
			, items_wait: []
			, item_cartel: {}
			, item_search: this.$storage.init({
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
				, status: 0
			})
			, is_confirm: false
			, confirm_type: ''
			, item_member: {}
			, items_deactivate: []
			, items_deact: [
				{ code: 'CA01600001', key: 7}
				, { code: 'CA01600002', key: 15}
				, { code: 'CA01600003', key: 30}
			]
			, deact_days: 7
			, tot_member_count: 0
		}
	}
	, computed: {
		confirm_option: function() {
			console.log('this.confirm_type', this.confirm_type)
			let t = {
				name: this.$language.common[this.confirm_type].name
				, main_txt: this.$language.common[this.confirm_type].main_txt
				, sub_txt: this.item_member.user_nick
			}

			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.cartl_member_list
					this.tot_member_count = result.data.tot_member_count
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getWaitMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_wait_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_wait = result.data.approval_waiting_cartl_member_list
					this.tot_member_count = result.data.tot_member_count
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getStopMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_stop_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_deactivate = result.data.cartl_stop_member_list
					this.tot_member_count = result.data.tot_member_count
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		,getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postInvite: async function(member_id){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_invite
					, data: {
						cartel_id: this.item_cartel.id
						, member_id: member_id
					}
					, name: 'postInvite'
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: '초대요청이 완료되었습니다'})
					await this.getCartel()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,onConfirm: function(type, item){
			this.confirm_type = type
			this.item_member = item
			this.is_confirm = true
		}

		, postCartelMemberConfirm: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_confirm
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, approval_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWaitMember()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}

		, postCartelMemberCancel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_cancel
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, approval_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWaitMember()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postCartelMemberOut:async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_drop
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, withddt_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postCartelMemberStop: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_stop
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, stop_member_number: this.item_member.member_number
						, cartl_stop_term_code: this.deact_days
					}
					, type: true
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}

		, postCartelMemberSafe: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_stop_cancel
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, release_member_number: this.item_member.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getStopMember()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postCartelMemberStatus: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_status
					, data: {
						cartel_id: this.$route.params.idx
						, user_id: this.item_member.user_id
						, status: this.item_member.status
						, deactivated_days: this.deact_days
					}
					, name: 'postCartelMemberStatus'
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, doConfirm: function(){
			switch (this.confirm_type){
				case 'join_confirm':
					this.item_member.status = 1
					this.postCartelMemberConfirm()
					break
				case 'join_cancel':
					this.item_member.status = -1
					this.postCartelMemberCancel()
					break
				case 'out':
					this.item_member.status = 3
					this.postCartelMemberOut()
					break
				case 'deactivate':
					this.item_member.status = 2
					this.postCartelMemberStop()
					break
				case 'safe':
					this.item_member.status = 1
					this.postCartelMemberSafe()
					break
			}

		}
		, cancelConfirm: function(){
			this.is_confirm = false
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)

		await this.getCartel()

		if(this.item_search.status == 0) {
			await this.getWaitMember()
		}else if(this.item_search.status == 2){
			await this.getStopMember()
		}else{
			await this.getData()
		}
	}

}
</script>

<style>
	.text-center { text-align: center}
</style>